import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.info.main,
    fontSize: '12px',
    letterSpacing: '0.02em'
  },
  textRoot: {
    marginBottom: '18px',
    '&::marker': {
      color: theme.palette.info.main
    }
  },
  root: {
    marginBottom: '12px',
    paddingLeft: '12px'
  }
}));

export default function AlsoIncludes(props) {
  const classes = useStyles();

  return (
    <List classes={{ root: classes.root }} sx={{ listStyleType: 'disc' }}>
      {props.includes.map((text, index) => (
        <ListItem
          key={index}
          classes={{ root: classes.textRoot }}
          sx={{ display: 'list-item', padding: 0 }}
        >
          <Typography variant="body1" classes={{ root: classes.text }}>
            {text}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}

AlsoIncludes.propTypes = {
  includes: PropTypes.array.isRequired
};

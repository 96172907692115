import Sleeving from '../../Constants/Sleeving';
import { get, find } from 'lodash';
import Plan from '../../Constants/Plan';
import getPlan from '../Customer/get-plan';
import UserPreference from '../../Constants/UserPreference';

export default function makeEmptyRoll(user) {
  const plan = getPlan(user);
  const isSuperNice = get(plan, 'id') === Plan.SUPER_NICE.id;
  const defaultRushPreference = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_RUSH.key
  );
  const defaultSleevingPreference = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_SLEEVING.key
  );
  const defaultPrintsPreference = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_4X6_PRINTS.key
  );
  const defaultNegativeAction = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_NEGATIVES_ACTION_OPTION.key
  );

  return {
    tempKey: Math.random() * 100000,
    customerId: get(user, 'id'),
    pickUpId: null,
    filmRollId: null,
    returnToCustomer: false,
    pushPullId: null,
    crossProcessId: null,
    addPrints: get(defaultPrintsPreference, 'value') === 'Yes',
    addRush: isSuperNice || get(defaultRushPreference, 'value') === 'Yes',
    sleeving: get(defaultSleevingPreference, 'value', Sleeving.NONE.value),
    negativesAction: get(defaultNegativeAction, 'value')
  };
}

import { get, isEmpty } from 'lodash';
import numeral from 'numeral';
import getMemberPrice from './get-member-price';
import ProductType from '../../Constants/ProductType';
import Plan from '../../Constants/Plan';

function isFilmRoll(product) {
  return product.sku.includes('135') || product.sku.includes('120');
}
export default function makePrice(line, product, plan) {
  if (product.productType === ProductType.PRINT) {
    const price = line.id
      ? line.amount / line.quantity
      : get(line, 'print.amount') / get(line, 'print.quantity');
    return numeral(price / 100).format('$0.00');
  }

  const productPrice = !isEmpty(plan)
    ? getMemberPrice(product)
    : get(product, 'price');
  const superNiceDiscount =
    get(plan, 'id') === Plan.SUPER_NICE.id && isFilmRoll(product);
  const nicePrice = superNiceDiscount ? productPrice * 0.95 : productPrice;

  return numeral((nicePrice * line.quantity) / 100).format('$0.00');
}

import { filter } from 'lodash';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Plan from '../../lib/Constants/Plan';
import MembershipOption from './MembershipOption';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: props =>
      props.isSmallScreen ? 'flex-start' : props.justify,
    scrollBehavior: 'smooth',
    flexWrap: 'nowrap',
    overflowX: props => (props.isSmallScreen ? 'scroll' : 'auto'),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      overflowX: 'scroll',
      width: 'auto'
    }
  }
}));

export default function MembershipOptions(props) {
  const classes = useStyles();
  const [active, setActive] = useState(
    props.isSmallScreen ? Plan.NICE.id : Plan.VERY_NICE.id
  );

  function makeOption(plan, index) {
    return (
      <MembershipOption
        key={plan.id}
        n={index + 1}
        plan={plan}
        onSelect={props.onSelect}
        active={active === plan.id}
        setActive={() => setActive(plan.id)}
        selectText={props.selectText}
      />
    );
  }

  function makeOptions() {
    const filtered = filter(Plan, 'active');

    if (props.isSmallScreen) {
      return (
        <Swiper slidesPerView={1.2}>
          {filtered.map((plan, index) => {
            return (
              <SwiperSlide key={plan.id}>{makeOption(plan, index)}</SwiperSlide>
            );
          })}
        </Swiper>
      );
    } else {
      return filtered.map(makeOption);
    }
  }

  return (
    <Grid classes={{ root: classes.root }} item>
      {makeOptions()}
    </Grid>
  );
}

MembershipOptions.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectText: PropTypes.string,
  justify: PropTypes.string
};

MembershipOptions.defaultProps = {
  selectText: 'Get Started',
  justify: 'center'
};

import { get, isEmpty, omitBy, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '../../lib/utils/Hook/use-window-size';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Header from '../../components/Header/Header';
import Hero from './Sections/Hero';
import { ReactComponent as PeaceIcon } from '../../assets/svg/shutter_peace.svg';
import Menu from './Sections/Menu';
import Development from './Sections/Development';
import Printing from './Sections/Printing';
import Shop from './Sections/Shop';
import Memberships from '../../components/Memberships/Memberships';
import OnDemand from './Sections/OnDemand';
import Community from './Sections/Community';
import Reviews from './Sections/Reviews';
import Footer from '../../components/Footer/Footer';
import SocialMediaSubmissionAPI from '../../lib/Api/SocialMediaSubmission';
import qs from 'qs';
import Gallery from './Sections/Gallery';
import getSettingsItem from '../../lib/utils/LocalStorage/get-settings-item';
import setSettingsItem from '../../lib/utils/LocalStorage/set-settings-item';
import SubscribePopUp from '../../components/SubscribePopUp/SubscribePopUp';
import Platform from './Sections/Platform';
import ContentPage from '../../lib/Constants/ContentPage';
import ContentPageSlide from './Sections/ContentPageSlide';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.info.main,
    height: '100%'
  },
  parallax: {
    flex: 1,
    width: '100%',
    height: props => `${props.screenSize.height}px`,
    overflow: props => (props.animationDone ? 'auto' : 'hidden'),
    overflowY: 'scroll',
    position: 'absolute',
    perspective: '8px',
    perspectiveOrigin: '0px'
  },
  peace: {
    bottom: '50px',
    height: '40px',
    opacity: props => (props.pageOpts.intersectedFooter ? 0 : 1),
    position: 'fixed',
    right: '50px',
    transform: props => `rotate(${props.scrollTop}deg)`,
    transformOrigin: '50% 50%',
    transition: 'opacity 500ms ease-in-out',
    visibility: props =>
      props.pageOpts.intersectedFooter ? 'hidden' : 'visible',
    width: '40px',
    zIndex: 1000,
    '& > path': {
      fill: props =>
        get(props, 'pageOpts.intersectedMemberships')
          ? theme.palette.success.main
          : theme.palette.bg.main,
      transition: 'fill 0.5s ease-in-out'
    },
    [theme.breakpoints.down('md')]: {
      bottom: '36px',
      height: '24px',
      right: '24px',
      width: '24px'
    }
  }
}));

const SETTINGS_KEY = 'landing-page';

export default function LandingPage(props) {
  const settings = getSettingsItem(SETTINGS_KEY);
  const screenSize = useWindowSize();
  const [scrollTop, setScrollTop] = useState(0);
  const [animationDone, setAnimationDone] = useState(
    get(settings, 'animationDone', true)
  );
  const routerLocation = get(props, 'location.pathname', '');
  const contentPage = ContentPage[routerLocation];
  const [pageOpts, setPageOpts] = useState({
    color: 'bg',
    bgColor: null,
    logoColor: 'bg'
  });
  const classes = useStyles({
    scrollTop,
    screenSize,
    pageOpts,
    animationDone
  });
  const parallaxRef = useRef(null);
  const sections = omitBy(
    {
      'NICE MEETING YOU': useRef(null),
      'DEVELOPMENT & SCANNING': useRef(null),
      [contentPage?.sectionTitle]: !isEmpty(contentPage) ? useRef(null) : null,
      PLATFORM: useRef(null),
      PRINTING: useRef(null),
      'FILM SHOP': useRef(null),
      'NICE COMMUNITY & REVIEWS': useRef(null)
    },
    isEmpty
  );
  const [selectedSection, setSelectedSection] = useState(null);
  const [submissionParams] = useState({
    limit: 25,
    offset: 0,
    sortBy: 'selectedAt',
    desc: true,
    search: '',
    selected: true
  });
  const [submission, setSubmission] = useState(null);
  const [submissions, setSubmissions] = useState({
    rows: [],
    count: 0
  });
  const [openSubscriber, setOpenSubscriber] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isEmpty(props.subscriber) || props.isLoggedIn) return;
      setOpenSubscriber(true);
    }, 15000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (isEmpty(submissionParams)) return;

    SocialMediaSubmissionAPI.getAllSelected(qs.stringify(submissionParams))
      .then(resp => {
        setSubmissions(subs => ({
          rows: uniqBy([...subs.rows, ...resp.submissions], 'id'),
          count: resp.count
        }));
      })
      .catch(console.log);
  }, [submissionParams]);

  useEffect(() => {
    if (!parallaxRef.current) return;

    const current = parallaxRef.current;

    current.addEventListener('scroll', handleScroll);
    return () => current.removeEventListener('scroll', handleScroll);
  }, [handleScroll, parallaxRef]);

  function handleScroll() {
    const innerHeight = parallaxRef.current.clientHeight;
    const scrollTop = parallaxRef.current.scrollTop;
    setScrollTop(scrollTop);

    if (scrollTop + 135 >= innerHeight) {
      setPageOpts(opts => ({
        ...opts,
        headerColor: 'bg',
        headerBGColor: 'primary',
        headerLogoColor: 'bg'
      }));
    } else {
      setPageOpts(opts => ({
        ...opts,
        headerColor: 'bg',
        headerBGColor: null,
        headerLogoColor: 'bg'
      }));
    }
  }

  function onSelectSection(section) {
    setSelectedSection(section);
    sections[section].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  function setNFCSettings(key, value) {
    const landingPageSettings = getSettingsItem(SETTINGS_KEY);
    setSettingsItem(SETTINGS_KEY, {
      ...landingPageSettings,
      [key]: value
    });
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      <SubscribePopUp
        onClose={() => setOpenSubscriber(false)}
        isLoggedIn={props.isLoggedIn}
        history={props.history}
        open={openSubscriber}
        subscriber={props.subscriber}
        addSubscriber={props.addSubscriber}
        theme="dark"
      />

      {submission && (
        <Gallery
          screenSize={screenSize}
          submission={submission}
          submissions={submissions.rows}
          onClose={() => setSubmission(null)}
        />
      )}
      <Header
        color={pageOpts.color}
        bgColor={pageOpts.headerBGColor}
        logoColor={pageOpts.headerLogoColor}
        history={props.history}
        shrink={pageOpts.intersectedMemberships}
        screenSize={screenSize}
        animationDone={animationDone}
        onAnimationDone={() => {
          setNFCSettings('animationDone', true);
          setAnimationDone(true);
        }}
      />
      <PeaceIcon className={classes.peace} />
      <Menu
        options={Object.keys(sections)}
        hide={!!pageOpts.intersectedFooter}
        lightenText={!!pageOpts.intersectedMemberships}
        selected={selectedSection}
        onSelect={onSelectSection}
      />
      <Grid classes={{ root: classes.parallax }} container ref={parallaxRef}>
        <Hero
          sectionRef={sections['NICE MEETING YOU']}
          contentTitle={get(contentPage, 'title')}
        />

        <Development
          screenSize={screenSize}
          scrollTop={scrollTop}
          sectionRef={sections['DEVELOPMENT & SCANNING']}
          content={contentPage}
        />

        {!isEmpty(contentPage) && (
          <ContentPageSlide
            screenSize={screenSize}
            scrollTop={scrollTop}
            content={contentPage}
            sectionRef={sections[(contentPage?.sectionTitle)]}
          />
        )}

        <Platform
          screenSize={screenSize}
          scrollTop={scrollTop}
          sectionRef={sections['PLATFORM']}
        />
        <Printing
          screenSize={screenSize}
          scrollTop={scrollTop}
          sectionRef={sections['PRINTING']}
          content={contentPage}
        />
        <Shop
          screenSize={screenSize}
          scrollTop={scrollTop}
          sectionRef={sections['FILM SHOP']}
          content={contentPage}
        />
        <Memberships
          screenSize={screenSize}
          scrollTop={scrollTop}
          history={props.history}
          intersected={!!pageOpts.intersectedMemberships}
          setIntersected={intersectedMemberships =>
            setPageOpts(opts => ({ ...opts, intersectedMemberships }))
          }
          sectionRef={sections['NICE COMMUNITY & REVIEWS']}
        />
        <OnDemand history={props.history} screenSize={screenSize} />
        <Community
          submissions={submissions.rows}
          onOpenImg={setSubmission}
          scrollTop={scrollTop}
          screenSize={screenSize}
          content={contentPage}
        />
        <Reviews content={contentPage} screenSize={screenSize} />
        <Footer
          screenSize={screenSize}
          scrollTop={scrollTop}
          history={props.history}
          clearSubscriber={props.clearSubscriber}
          addSubscriber={props.addSubscriber}
          subscriber={props.subscriber}
          intersected={!!pageOpts.intersectedFooter}
          setIntersected={intersectedFooter =>
            setPageOpts(opts => ({ ...opts, intersectedFooter }))
          }
        />
      </Grid>
    </Grid>
  );
}

LandingPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  addSubscriber: PropTypes.func.isRequired,
  clearSubscriber: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  signup: PropTypes.func.isRequired,
  subscriber: PropTypes.object
};

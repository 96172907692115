import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { ReactComponent as CheckIcon } from '../../assets/svg/confirmation.svg';

const useStyles = makeStyles(theme => ({
  checkIcon: {
    height: '16px',
    marginRight: '8px',
    width: '16px',
    '& > g > g': {
      stroke: theme.palette.secondary.main
    }
  },
  club: {
    color: theme.palette.secondary.main,
    fontSize: '8px',
    letterSpacing: '0.16em'
  },
  info: {
    color: theme.palette.info.main,
    fontSize: '8px',
    letterSpacing: '0.16em'
  },
  root: {
    marginBottom: '12px'
  }
}));

export default function Benefit(props) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} container alignItems="center">
      <CheckIcon className={classes.checkIcon} />
      <Typography variant="h5" classes={{ root: classes.club }}>
        {props.clubText}&nbsp;
      </Typography>
      <Typography variant="h5" classes={{ root: classes.info }}>
        {props.infoText}
      </Typography>
    </Grid>
  );
}

Benefit.propTypes = {
  clubText: PropTypes.string,
  infoText: PropTypes.string
};

Benefit.defaultProps = {
  clubText: '',
  infoText: ''
};

import React from 'react';

export default {
  NICE: {
    id: 'NICE',
    active: true,
    label: 'Nice',
    desc: 'For the casual film enthusiast',
    price: 19999,
    rollCredits: 10,
    pickUpCredits: 0,
    sku: 'nicemembership',
    benefits: [
      {
        clubText: '10',
        infoText: 'Dev & Scan Credits'
      },
      { clubText: '$9.99 / Roll dev & Scan' },
      {
        clubText: '10% Off',
        infoText: 'Printing Services'
      },
      {
        clubText: 'Unlimited',
        infoText: 'Scan Storage'
      },
      {
        clubText: 'Free',
        infoText: 'Pre Paid USPS Labels'
      },
      {
        clubText: '$0.33',
        infoText: 'Hi-Res 4K TIFF Unlocks*'
      }
    ],
    alsoIncludes: [
      'Basic discounts on film rolls and other products in the online store'
    ],
    clarifications: [
      '*4K TIFF unlocks start at $0.33 per frame when the entire roll is unlocked simultaneously. Members still get a 33% discount and may unlock individual frames for $0.99.'
    ]
  },
  VERY_NICE: {
    id: 'VERY_NICE',
    active: true,
    label: 'Very Nice',
    desc: 'For the casual film enthusiast',
    price: 49999,
    rollCredits: 20,
    pickUpCredits: 0,
    sku: 'verynicemembership',
    benefits: [
      {
        clubText: '20',
        infoText: 'Dev & Scan Credits'
      },
      { clubText: '$9.99 / Roll dev & Scan' },
      {
        clubText: '15% Off',
        infoText: 'Printing Services'
      },
      {
        clubText: 'Unlimited',
        infoText: 'Scan Storage'
      },
      {
        clubText: 'Free',
        infoText: 'Pre Paid USPS Labels'
      },
      {
        clubText: 'Included Hi-Res 4K TIFF Scans',
        infoText: ''
      },
      {
        clubText: 'Zip Downloads for TIFFs by roll',
        infoText: ''
      },
      {
        clubText: 'Dev & Scan Priority Queue*',
        infoText: ''
      }
    ],
    alsoIncludes: [
      'Basic discounts on film rolls and other products in the online store',
      '50% off return shipping for negatives'
    ],
    clarifications: ['*Priority Queue is 5 business days or less.']
  },
  SUPER_NICE: {
    id: 'SUPER_NICE',
    active: true,
    label: 'Super Nice',
    desc: 'For the casual film enthusiast',
    price: 99999,
    rollCredits: 30,
    pickUpCredits: 0,
    sku: 'supernicemembership',
    benefits: [
      {
        clubText: '30',
        infoText: 'Dev & Scan Credits'
      },
      { clubText: '$9.99 / Roll dev & Scan' },
      {
        clubText: '20% Off',
        infoText: 'Printing Services'
      },
      {
        clubText: 'Unlimited',
        infoText: 'Scan Storage'
      },
      {
        clubText: 'Free',
        infoText: 'Pre Paid USPS Labels'
      },
      {
        clubText: 'Included Hi-Res 4K TIFF Scans',
        infoText: ''
      },
      {
        clubText: 'Zip Downloads for TIFFs by roll',
        infoText: ''
      },
      {
        clubText: 'Included Rush',
        infoText: 'Dev & Scan'
      }
    ],
    alsoIncludes: [
      'Basic discounts on film rolls and other products in the online store',
      'Free return shipping for negatives',
      'additional 5% fixed discount on all film roll purchases'
    ]
  },
  NICE_LEGACY: {
    id: 'NICE_LEGACY',
    active: false,
    label: 'Nice',
    desc: 'For the casual film enthusiast',
    price: 9999,
    rollCredits: 10,
    pickUpCredits: 0,
    benefits: [
      <span key={1}>
        Development and scan for{' '}
        <b>
          <u>10</u>
        </b>{' '}
        rolls of film (C41, B/W)
      </span>,
      '$10 for each additional roll',
      'Mail in and pick up options (NYC Only)',
      'Unlimited rollover for unused rolls'
    ]
  },
  NICE_LEGACY_MONTHLY: {
    id: 'NICE_LEGACY',
    active: false,
    label: 'Nice',
    desc: 'For the every day film shooter',
    price: 2999,
    rollCredits: 3,
    pickUpCredits: 1,
    benefits: [
      <span key={2}>
        Development and scan for{' '}
        <b>
          <u>3</u>
        </b>{' '}
        rolls of film (C41, B/W)
      </span>,
      '1 free pick up directly from your door',
      'Unlimited rollover for unused rolls',
      '$12.99 for each additional roll',
      'cancel anytime'
    ]
  }
};

import getPlan from '../Customer/get-plan';
import Plan from '../../Constants/Plan';

export default function isUnlockTier(user) {
  const userPlan = getPlan(user);

  return (
    userPlan?.id === Plan.SUPER_NICE.id || userPlan?.id === Plan.VERY_NICE.id
  );
}

import { capitalize, startCase, toLower } from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LightRoomIcon from './LightRoomIcon';
import Divider from '@mui/material/Divider';
import Benefit from './Benefit';
import AlsoIncludes from './AlsoIncludes';
import Plan from '../../lib/Constants/Plan';
import Clarifications from './Clarifications';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%'
  },
  getStarted: {
    background: props =>
      props.active ? theme.palette.secondary.main : 'transparent',
    border: props =>
      props.active ? '0' : `1px solid ${theme.palette.secondary.main}`,
    color: props =>
      props.active ? theme.palette.bg.main : theme.palette.secondary.main,
    borderRadius: `32px`,
    boxShadow: `0px 12px 64px rgba(0, 0, 0, 0.04)`,
    cursor: 'pointer',
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    letterSpacing: '0.16em',
    margin: '24px 0px',
    padding: '14px 0px',
    width: '260px',
    '&:hover': {
      background: theme.palette.secondary.light,
      boxShadow: `0px 12px 64px rgba(0, 0, 0, 1)`
    }
  },
  root: {
    background: props => (props.active ? '#ffffff' : 'transparent'),
    borderRadius: '32px',
    border: props =>
      props.active ? 'none' : `1px solid ${theme.palette.disableGray.main}`,
    boxShadow: props =>
      props.active ? '0px 12px 120px rgba(0, 0, 0, 0.08)' : 'none',
    cursor: 'pointer',
    flexShrink: '0',
    margin: '0px 10px',
    padding: '30px 20px',
    width: '300px'
  },
  planDetails: {},
  planDetailsSubtitle: {
    color: theme.palette.primary.main,
    fontSize: '8px',
    letterSpacing: '0.16em',
    margin: '24px 0px'
  },
  planHeader: {
    marginTop: '24px'
  },
  planName: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontSize: '28px'
  },
  planNameItalic: {
    fontStyle: 'italic'
  },
  planPrice: {
    color: theme.palette.secondary.main,
    fontSize: '10px',
    letterSpacing: '0.16em',
    lineHeight: '12px',
    marginTop: '6px'
  },
  planPriceMonthly: {
    color: theme.palette.primary.main,
    fontSize: '7px',
    letterSpacing: '0.16em',
    lineHeight: '12px',
    marginTop: '6px',
    maxWidth: '120px'
  }
}));

export default function MembershipOption(props) {
  const classes = useStyles(props);

  function getBGColor() {
    if (props.active) return 'secondary';
    if (props.plan.id === Plan.SUPER_NICE.id) return 'accent';

    return 'info';
  }

  function getColor() {
    if (props.active) return 'bg';
    if (props.plan.id === Plan.SUPER_NICE.id) return 'bg';

    return 'disableGray';
  }

  function makeName() {
    const base = toLower(startCase(props.plan.id));
    const [first, second] = base.split(' ');

    if (!second) return <span>{first}</span>;

    return (
      <React.Fragment>
        <span className={classes.planNameItalic}>{first}</span>
        {` `}
        <span>{capitalize(second)}</span>
      </React.Fragment>
    );
  }

  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      onMouseOver={props.setActive}
      onClick={props.setActive}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography>{props.n}</Typography>
        <LightRoomIcon bg={getBGColor()} color={getColor()} />
      </Grid>

      <Grid container classes={{ root: classes.body }}>
        <Grid
          container
          classes={{ root: classes.planHeader }}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h1" classes={{ root: classes.planName }}>
            {makeName()}
          </Typography>
          <Grid item>
            <Typography
              variant="h5"
              classes={{ root: classes.planPrice }}
              textAlign="right"
            >
              {`${numeral(props.plan.price / 100)
                .format('$000.00')
                .slice(0, -3)}`}
              {` `}/{` `}Year
            </Typography>
            <Typography
              variant="h5"
              classes={{ root: classes.planPriceMonthly }}
              textAlign="right"
            >
              {`${numeral(props.plan.price / 100 / 12).format('$00.00')}`}
              {` `}/{` `}Month (Billed Annually)
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Button
            classes={{ root: classes.getStarted }}
            onClick={() => props.onSelect(props.plan)}
          >
            {props.selectText}
          </Button>
        </Grid>
        <Divider classes={{ root: classes.divider }} />

        <Grid classes={{ root: classes.planDetails }}>
          <Typography
            variant="h5"
            classes={{ root: classes.planDetailsSubtitle }}
          >
            What you get:
          </Typography>
          {props.plan.benefits.map((benefit, index) => (
            <Benefit key={index} {...benefit} />
          ))}
        </Grid>
        <Divider classes={{ root: classes.divider }} />
        <Grid classes={{ root: classes.planDetails }}>
          <Typography
            variant="h5"
            classes={{ root: classes.planDetailsSubtitle }}
          >
            Also includes:
          </Typography>
          <AlsoIncludes includes={props.plan.alsoIncludes} />
          {props.plan.clarifications && (
            <Clarifications clarifications={props.plan.clarifications} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

MembershipOption.propTypes = {
  n: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectText: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired
};

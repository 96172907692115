import { chain, get, keyBy } from 'lodash';
import Plan from '../../Constants/Plan';

export default function getPlan(user) {
  const plansByPrice = keyBy(Plan, 'price');

  const sub = chain(user)
    .get('stripeCustomer.subscriptions.data')
    .find(sub => {
      return plansByPrice[get(sub, 'items.data[0].plan.amount')];
    })
    .value();

  return plansByPrice[get(sub, 'items.data[0].plan.amount')];
}

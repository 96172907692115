import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as FlashIcon } from '../../assets/svg/flash_alt.svg';

const useStyles = makeStyles(theme => ({
  icon: {
    height: props => `${props.size / 2}px`,
    width: props => `${props.size / 2}px`,
    '& > path': {
      fill: props => theme.palette[props.color].main
    }
  },
  root: {
    background: props => theme.palette[props.bg].main,
    border: props =>
      props.withBorder
        ? `1px solid ${theme.palette[props.color].main}`
        : 'none',
    borderRadius: '50%',
    height: props => `${props.size}px`,
    width: props => `${props.size}px`
  }
}));

export default function LightRoomIcon(props) {
  const classes = useStyles(props);

  return (
    <Grid
      classes={{ root: classes.root }}
      container
      alignItems="center"
      justifyContent="center"
    >
      <FlashIcon className={classes.icon} />
    </Grid>
  );
}

LightRoomIcon.propTypes = {
  withBorder: PropTypes.bool,
  bg: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number
};

LightRoomIcon.defaultProps = {
  bg: 'disableGray',
  color: 'info',
  withBorder: false,
  size: 40
};

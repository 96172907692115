import { get } from 'lodash';
import moment from 'moment';

export default function nextSubscriptionDate(user = {}) {
  const nextSubscriptionDate = get(
    user,
    'stripeCustomer.subscriptions.data[0].current_period_end'
  );

  return moment.unix(nextSubscriptionDate);
}

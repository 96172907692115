import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';
import Typography from '@mui/material/Typography';
import MembershipOptions from '../MembershipOptions/MembershipOptions';

const useStyles = makeStyles(theme => ({
  header: {
    margin: '96px 0px 24px 0px'
  },
  headerTitle: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    lineHeight: '20px',
    margin: '12px 0px'
  },
  headerSubtitle: {
    fontSize: '32px',
    lineHeight: '32px',
    textAlign: 'center',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      maxWidth: '200px'
    }
  },
  options: {
    margin: '24px 0px 96px 0px'
  },
  root: {
    background: theme.palette.bg.main
  }
}));

export default function Memberships(props) {
  const classes = useStyles();

  useEffect(() => {
    if (!props.sectionRef || !props.sectionRef.current) return;

    const offsetTop = get(props.sectionRef, 'current.offsetTop', 0);
    const diff = props.scrollTop + props.screenSize.height / 2 - offsetTop;
    const intersected = diff > 0;

    if (props.intersected === intersected) return;

    props.setIntersected(intersected);
  }, [props.scrollTop, props.sectionRef]);

  return (
    <Grid classes={{ root: classes.root }} container ref={props.sectionRef}>
      <a href="#memberships" />
      <Grid
        container
        classes={{ root: classes.header }}
        alignItems="center"
        direction="column"
      >
        <Typography variant="h5" classes={{ root: classes.headerTitle }}>
          Memberships
        </Typography>
        <Typography variant="h4" classes={{ root: classes.headerSubtitle }}>
          ALL YOU NEED, ALL AT ONCE
        </Typography>
      </Grid>
      <Grid
        container
        classes={{ root: classes.options }}
        justifyContent="center"
      >
        <MembershipOptions
          onSelect={tier =>
            props.history.push(`/memberships/purchase?tier=${tier.id}`)
          }
          isSmallScreen={props.screenSize.isSmallScreen}
        />
      </Grid>
    </Grid>
  );
}

Memberships.propTypes = {
  screenSize: PropTypes.object.isRequired,
  scrollTop: PropTypes.number,
  history: PropTypes.object.isRequired,
  intersected: PropTypes.bool,
  setIntersected: PropTypes.func,
  sectionRef: PropTypes.object
};

Memberships.defaultProps = {
  intersected: false,
  scrollTop: 0,
  sectionRef: null,
  setIntersected: () => {}
};

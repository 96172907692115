import { get, omit } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { ReactComponent as CheckIcon } from '../../assets/svg/confirmation.svg';
import { ReactComponent as ErrorIcon } from '../../assets/svg/error.svg';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {},
  label: {
    color: props =>
      props.labelColor
        ? theme.palette[props.labelColor].main
        : 'rgba(58,47,41,0.7)',
    top: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  labelShrink: {
    top: props => (props.compact ? '10px' : '0px'),
    display: props => (props.showLabel ? 'default' : 'none')
  },
  inputRoot: {
    alignItems: 'center',
    color: props => theme.palette[props.color].main,
    display: 'flex',
    padding: props => (props.compact ? '0px' : '4px 0px 10px 0px'),
    '& input[type=number]': {
      padding: props => (props.compact ? '0px' : '4px 0px 5px 0px')
    }
  },
  inputUnderline: {
    '&:before': {
      borderBottom: props =>
        `1px solid ${theme.palette[props.borderColor].main}`
    },
    '&:after': {
      borderBottom: props =>
        `1px solid ${theme.palette[props.borderColorAfter].main}`
    }
  }
}));

export default function NFCTextField(props) {
  const classes = useStyles(props);

  return (
    <TextField
      variant={props.variant}
      classes={{
        root: classes.root
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          root: classes.label,
          shrink: classes.labelShrink
        }
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: classes.inputRoot,
          underline: classes.inputUnderline,
          ...props.inputClasses
        },
        endAdornment: get(props.InputProps, 'endAdornment') || (
          <InputAdornment position="end">
            {props.valid && <CheckIcon />}
            {props.error && <ErrorIcon />}
          </InputAdornment>
        )
      }}
      {...omit(
        props,
        'compact',
        'valid',
        'showLabel',
        'inputClasses',
        'InputProps',
        'InputLabelProps',
        'borderColor',
        'borderColorAfter',
        'labelColor',
        'color'
      )}
    />
  );
}

NFCTextField.propTypes = {
  compact: PropTypes.bool,
  error: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  borderColor: PropTypes.string,
  borderColorAfter: PropTypes.string,
  color: PropTypes.string,
  labelColor: PropTypes.string,
  valid: PropTypes.bool,
  showLabel: PropTypes.bool,
  inputClasses: PropTypes.object,
  variant: PropTypes.string
};

NFCTextField.defaultProps = {
  borderColor: 'info',
  borderColorAfter: 'primary',
  color: 'primary',
  compact: false,
  labelColor: null,
  valid: false,
  showLabel: false,
  inputClasses: null,
  variant: 'standard'
};
